import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import '../styles/_bootstrap.scss'
import './index.scss';
import './open.scss';
import { RouteComponentProps } from '@reach/router';


class Open extends React.PureComponent<RouteComponentProps, openState> {
    constructor(props) {
        super(props)
        this.state = {
            accountTypeValue: ""
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="page">
                    <div className="container-fluid hero">
                        <section className="section hero-small background-gradient">
                            <div className="hero-no-image padding-top-XL padding-bottom-M container">
                                <h1 className="text-left">Open Account</h1>
                            </div>
                        </section>
                    </div>

                    <div className="container-fluid padding-none body">
                        <section className="section open">
                            <div className="col-md-8 col-md-offset-2 col-sm-12 margin-bottom-M intro">
                                <p className="text-L padding-bottom-M">While all customers have access to DirekTek's vast product range, live support and online tools, we introduced Mini, Standard and Enterprise account types to better cater to your individual needs.</p>
                            </div>
                            <div className="container padding-none">
                                <div className="col-sm-12 col-md-8">
                                    <h3>Account Tiers</h3>
                                    <table className="table text-M text-center hidden-sm hidden-xs">
                                        <thead>
                                            <tr>
                                                <th style={{ width: 25 + "%" as "25%", borderRight: "2px solid #ddd" }} className="text-right"><br /><small><span style={{ fontWeight: "normal" as "normal", color: "#8d8d8e" as "#8d8d8e" }}>Annual Spend</span></small></th>
                                                <th className="text-center" style={{ width: 25 + "%" as "25%" }}>Mini<br />
                                                    <small>&lt; &#163;10,000 pa</small></th>
                                                <th className="text-center" style={{ width: 25 + "%" as "25%" }}>Standard<br />
                                                    <small>&#163;10,000 - &#163;150,000 pa</small></th>
                                                <th className="text-center" style={{ width: 25 + "%" as "25%" }}>Enterprise<br />
                                                    <small>&gt; &#163;150,000 pa</small></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Pricing</td>
                                                <td>Best price</td>
                                                <td>Best price</td>
                                                <td>Best price</td>
                                            </tr>
                                            <tr className="background-grey">
                                                <td style={{ borderRight: "2px" + "solid" + "ddd", textAlign: "left" /*!important*/ }}>Ordering</td>
                                                <td>Web</td>
                                                <td>Web, EDI/API, Email, Telephone</td>
                                                <td>Web, EDI/API, Email</td>
                                            </tr>
                                            <tr style={{ borderBottom: "transparent" }}>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Delivery Options <br />
                                                    <a href="https://help.direktek.co.uk/en/article/28/services-pricing"><small>See our tariffs</small></a></td>
                                                <td>All</td>
                                                <td>All</td>
                                                <td>All &amp; bespoke</td>
                                            </tr>
                                            <tr className="background-grey">
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Payment Terms</td>
                                                <td>Basic</td>
                                                <td>Perferred</td>
                                                <td>Preferred</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Online Tools</td>

                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-check"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr className="background-grey">
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Access Product Info/Assets</td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>API Access</td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr className="background-grey">
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>Dedicated Account Manager</td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>White Label</td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr className="background-grey">
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>End User Helpline</td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                            <tr>
                                                <td className="text-left" style={{ borderRight: "2px solid #ddd" }}>SLA</td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fa fa-lg"
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                                <td> <FontAwesomeIcon
                                                    className="fafa-lg sub-item-icon"
                                                    icon={faCheck}
                                                    fixedWidth
                                                    size="sm"
                                                    style={{
                                                        fontSize: 1.1 + "em",
                                                        color: "#3e4143 !important",
                                                    }} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div aria-multiselectable="true" className="panel-group hidden-md hidden-lg" id="accordion" role="tablist">
                                        <div className="panel panel-default text-M margin-top-M">
                                            <div className="panel-heading" id="headingOne" role="tab">
                                                <h4 className="panel-title" style={{ fontSize: 18 + "px" + "!important" }}><a aria-controls="collapseOne" aria-expanded="true" data-parent="#accordion" data-toggle="collapse" href="open.html#collapseOne" role="button">Mini<br />
                                                    <small><span style={{ fontWeight: "normal", color: "#8d8d8e" }}>Annual Spend: </span> &lt; &#163;10,000 pa</small></a></h4>
                                            </div>
                                            <div aria-labelledby="headingOne" className="panel-collapse collapse in" id="collapseOne" role="tabpanel">
                                                <div className="panel-body padding-none">
                                                    <ul className="list-unstyled account-wrap1">
                                                        <li>Best price</li>
                                                        <li>Access Product Info/Assets</li>
                                                        <li>API Access</li>
                                                        <li>Web ordering</li>
                                                        <li>All delivery options</li>
                                                        <li>Basic payment terms</li>
                                                        <li style={{ borderBottom: "1px solid #fff !important" }}>Access to online tools</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel panel-default text-M margin-top-M">
                                            <div className="panel-heading" id="headingTwo" role="tab">
                                                <h4 className="panel-title"><a aria-controls="collapseTwo" aria-expanded="false" className="collapsed" data-parent="#accordion" data-toggle="collapse" href="open.html#collapseTwo" role="button">Standard<br />
                                                    <small><span style={{ fontWeight: "normal", color: "#8d8d8e" }}>Annual Spend: </span> &#163;10,000 - &#163;150,000 pa</small></a></h4>
                                            </div>
                                            <div aria-labelledby="headingTwo" className="panel-collapse collapse" id="collapseTwo" role="tabpanel">
                                                <div className="panel-body padding-none">
                                                    <ul className="list-unstyled account-wrap1">
                                                        <li>Best price</li>
                                                        <li>Access Product Info/Assets</li>
                                                        <li>API Access</li>
                                                        <li>Web, EDI/API, Email and Telephone ordering</li>
                                                        <li>All delivery options</li>
                                                        <li>Perferred payment terms</li>
                                                        <li>Access to online tools</li>
                                                        <li style={{ borderBottom: "1px solid #fff !important" }}>Dedicated account manager</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel panel-default text-M margin-top-M">
                                            <div className="panel-heading" id="headingThree" role="tab">
                                                <h4 className="panel-title"><a aria-controls="collapseThree" aria-expanded="false" className="collapsed" data-parent="#accordion" data-toggle="collapse" href="open.html#collapseThree" role="button">Enterprise<br />
                                                    <small><span style={{ fontWeight: "normal", color: "#8d8d8e" }}>Annual Spend: </span> &gt; &#163;150,000 pa</small></a></h4>
                                            </div>
                                            <div aria-labelledby="headingThree" className="panel-collapse collapse" id="collapseThree" role="tabpanel">
                                                <div className="panel-body padding-none">
                                                    <ul className="list-unstyled account-wrap1">
                                                        <li>Best price</li>
                                                        <li>Access Product Info/Assets</li>
                                                        <li>API access</li>
                                                        <li>Web, EDI/API and Email ordering</li>
                                                        <li>All &amp; bespoke delivery options</li>
                                                        <li>Perferred payment terms</li>
                                                        <li>Access to online tools</li>
                                                        <li>Dedicated account manager</li>
                                                        <li>White label</li>
                                                        <li>End user helpline</li>
                                                        <li style={{ borderBottom: "1px solid #fff !important" }}>SLA</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4 cta-list">
                                    <div className="text-left cta-box">
                                        <h3>Trade with DirekTek</h3>
                                        <div className="padding-top-M padding-bottom-M">
                                            <p className="text-M" style={{ color: "#fff" }}>Account tier</p>
                                            <Dropdown as='select' style={{ padding: 10 + "px", borderColor: "#dcdcdc", width: 100 + "%" }} 
                                            className="text-M div-toggler selector text-dark" 
                                            onChange={this.showExtraFields}>
                                                {/* <select className="text-M div-toggler selector text-dark" data-target=".account-types" >                                                 */}
                                                <option data-show=".none" value="Select account type...">
                                                    Select account tier...
					                        	</option>
                                                <option data-show=".mini" style={{ width: "100%" }} value="Mini">
                                                    Mini
						                        </option>
                                                <option data-show=".standard" style={{ width: "100%" }} value="Standard">
                                                    Standard
					                        	</option>
                                                <option data-show=".enterprise" style={{ width: "100%" }} value="Enterprise">
                                                    Enterprise
						                        </option>
                                            </Dropdown>

                                        </div>
                                        <div className="account-types padding-top-M padding-bottom-M">
                                            <Button className="btn squared btn-success text-M mini" onClick={this.followLink} >Start application</Button>
                                        </div>
                                    </div><br />
                                    <small className="padding-bottom-S">Your account tier will be automatically reassessed on a rolling 6 months basis and upgraded or downgraded based on a projected yearly turnover.</small>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    private showExtraFields = (e) => {

        e.persist()
        const fetchedAccountType: string = e.target[e.target.selectedIndex].getAttribute('data-show')
        console.log(fetchedAccountType.replace(".", ""))
        this.setState({ accountTypeValue: fetchedAccountType.replace('.', "") })
        
    }

    private followLink = () => {
        
        let link = `https://form.jotformeu.com/61391935960362?clickTo=${this.state.accountTypeValue}`
        if(link.search('none') !== -1) return
        console.log(link)
        window.location.assign(link)
    }
}

interface openState {
    accountTypeValue: string
}


export default Open; 
