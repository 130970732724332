import * as React from 'react';
import './header.scss';
import '../styles/_footer.scss'
import CookiePopup from '../components/cookie-popup'
import { CookiesProvider } from 'react-cookie';

interface FooterState {
    showCookies: boolean
}

class Footer extends React.PureComponent<{}, FooterState> {
    constructor(props: any) {
        super(props)
        this.state = {
            showCookies: true
        }
        this.handleCloseCookiePopup = this.handleCloseCookiePopup.bind(this)
    }
    public render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-xs-12">
                            <div className="cta-open">
                                <h4>Ready to get Started?</h4>
                                <a href="/open/" className="btn btn-success squared">Open an account</a>
                            </div>
                        </div>
                        <div className="col-md-9 col-xs-12">
                            <div className="col-md-3 col-sm-3 col-xs-6 item">
                                <h4>Learn More</h4>
                                <ul>
                                    <li><a href="/brands/">Brands</a></li>
                                    <li><a href="/dropship/">Dropship</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-6 item">
                                <h4>Integrations</h4>
                                <ul>
                                    <li><a href="https://help.direktek.co.uk/en/collection/11/integrations">Feeds</a></li>

                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-6 item">
                                <h4>Resources</h4>
                                <ul>
                                    <li><a href="https://help.direktek.co.uk/en/article/28/services-pricing">Delivery Prices</a></li>
                                    <li><a href="/tracking/">Delivery Tracking</a></li>
                                    <li><a href="/make-payment/">Make Payment</a></li>
                                    <li><a href="https://help.direktek.co.uk/en/article/32/raising-a-return">Returns</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3 col-xs-6 item">
                                <h4>Company</h4>
                                <ul>
                                    <li><a href="/about/">About</a></li>
                                    <li><a href="/platform-partners/">Partners</a></li>
                                    <li><a href="/jobs/">Jobs</a></li>
                                    <li><a href="/contact/">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row subfooter">
                        <div className="col-md-3 col-xs-12 small" id="vat">
                            All prices exclude VAT
                        </div>
                        <div className="col-md-9 col-xs-12 small text-right" id="copyright">
                            &copy; DirekTek Ltd &#8226; All rights reserved &#8226; Company Number: 2656105 &#8226; VAT Number: GB 579279181 &#8226;
                            <a href="/policies/terms-of-service/" className="text-underline">Terms of Service</a> &#8226; <a href="/policies/privacy/" className="text-underline">Privacy Policy</a>
                        </div>
                        <CookiesProvider>
                            <CookiePopup handleClose={this.handleCloseCookiePopup} /> : <div />
                        </CookiesProvider>
                    </div>
                </div>
            </footer>
        )
    }
    public handleCloseCookiePopup = () => {
        this.setState({ showCookies: false })
    }
}

export default Footer;
