import * as React from 'react';
import Helmet from 'react-helmet';
import './header.scss';
import '../styles/_header.scss';
import favicon from "../../static/icons/icon-16x16.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCube, faCode, faBriefcase, faEnvelope, faSearch, faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { Nav, Navbar, NavDropdown, Container, Dropdown } from 'react-bootstrap';
import NavLink from 'react-bootstrap/NavLink';

class Header extends React.PureComponent<any, HeaderState> {
  constructor(props) {
    super(props);

    this.toggleServices = this.toggleServices.bind(this);
    this.toggleCompany = this.toggleCompany.bind(this);
    this.toggleProduct = this.toggleProduct.bind(this);
    this.state = {
      productsDropdown: false,
      companyDropdown: false,
      servicesDropdown: false
    };
  }
  public render() {

    const dropdownStyles = {
      visbility: "hidden",
      transition: "0.2s 1s"
    }

    return (
      <header>
        <nav className="navbar navbar-global header-light">
          <Container>

            <Navbar bg="light" variant="light">
              <Navbar.Toggle aria-controls='navbar' />
              <Navbar.Collapse id="basic-nav-bar">
                <Nav className="container">
                  <div className="navbar-header" style={{ float: "left" }}>
                    <a href="/">
                      <img src="https://assets.direktek.co.uk/images/logo/vectorw.png" alt="DirekTek" height="32" width="auto" id="light" />
                    </a>
                  </div>
                  <ul className="nav navbar-nav">
                    <li>
                    </li>
                    <Dropdown
                      onMouseEnter={this.toggleProduct}
                      onMouseLeave={this.toggleProduct}
                      show={this.state.productsDropdown}
                      title="Products"
                      id="basic-nav-dropdown"
                      className="dropdown center-item hidden-xs"
                      as="li">

                      <Nav.Link href="/brands" role="button" style={{ paddingRight: 0 + "px" }}>Brands</Nav.Link> {/*previously Products*/}
                      {/* <Dropdown.Menu as="ul" className="multi-column columns-3">

                        <ul className="col-xs-4 multi-column-dropdown">
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/photo.html">
                            <li>
                              <img
                                className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/photo_red.png" />
                              <span>
                                <p className="sub-item">Photo</p>
                                <p className="sub-item-small">DSLR, CSC, Compact</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/smart_tech.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/smart_tech_red.png" />
                              <span>
                                <p className="sub-item">Smart Tech</p>
                                <p className="sub-item-small">IP Cameras, Wearables</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/home_office.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/home_office_red.png" />
                              <span>
                                <p className="sub-item">Home &amp; Office</p>
                                <p className="sub-item-small">Tablets, Energy, Cables</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                        </ul>
                        <ul className="col-xs-4 multi-column-dropdown">
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/video.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/video_red.png" />
                              <span>
                                <p className="sub-item">Video</p>
                                <p className="sub-item-small">Action, 360, Car</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/sound_vision.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/sound_vision_red.png" />
                              <span>
                                <p className="sub-item">Sound &amp; Vision</p>
                                <p className="sub-item-small">TV, Audio, VR</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/digital_storage.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/digital_storage_red.png" />
                              <span>
                                <p className="sub-item">Digital Storage</p>
                                <p className="sub-item-small">Memory Cards, USB</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                        </ul>
                        <ul className="col-xs-4 multi-column-dropdown">
                          <Dropdown.Item
                            disabled={true}
                            href="/browse/optics.html">
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left" }} width="50" height="50" src="/images/cats/brackets_mounts_red.png" />
                              <span>
                                <p className="sub-item">Brackets &amp; Mounts</p>
                                <p className="sub-item-small">TV/PC Brackets, Trolleys</p>
                              </span>
                            </li>
                          </Dropdown.Item>
                        </ul>
                        <ul className="col-xs-12 multi-column-dropdown">
                          <Dropdown.Divider role="separator" className="divider" />
                          <Dropdown.Item href="/brands">
                            <li style={{ textAlign: "center" as 'center' }}>
                              <FontAwesomeIcon
                                className="fa fa-cube fa-lg"
                                icon={faCube}
                                size="2x"
                                style={{
                                  // float: "left",
                                  paddingRight: 5 + 'px',
                                  textAlign: "center" as "center",
                                  marginLeft: 'auto' as 'auto',
                                  marginRight: 'auto' as 'auto',
                                  display: 'inline-block' as 'inline-block'
                                }} />
                              Our Brand Partners
                            </li>
                          </Dropdown.Item>
                        </ul>
                              </Dropdown.Menu>*/}
                    </Dropdown>
                    <li className="center-item hidden-xs">
                      <Nav.Link href="/dropship" role="button">Dropship</Nav.Link>
                    </li>
                    {/* <li className="center-item hidden-xs">
                      <Dropdown
                        onMouseEnter={this.toggleServices}
                        onMouseLeave={this.toggleServices}
                        show={this.state.servicesDropdown}
                        id="basic-nav-dropdown"
                        className="dropdown center-item hidden-xs">
                        <Nav.Link href="/brands" role="button">Services</Nav.Link>
                        <Dropdown.Menu className="dropdown-menu" as="ul">
                          <Dropdown.Item href="/services/thunderbolt" className="dropdown center-item hidden-xs" style={dropdownStyles}>
                            <li>
                              <FontAwesomeIcon
                                className="fafa-lg sub-item-icon"
                                icon={faLightbulb}
                                size="lg"
                                fixedWidth
                                style={{
                                  float: "left",
                                  paddingRight: 5 + 'px',
                                  color: "#3e4143 !important",
                                  fontSize: "1.3333333em"
                                }} />
                              <p>Thunderbolt</p>
                            </li>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li> */}
                    <li className="center-item hidden-xs">
                      <Nav.Link href="https://help.direktek.co.uk/en/collection/11/integrations" className="center-item nav">
                        Integrations
                    </Nav.Link>
                    </li>
                    <li className="center-item hidden-xs">
                      <Dropdown
                        onMouseEnter={this.toggleCompany}
                        onMouseLeave={this.toggleCompany}
                        show={this.state.companyDropdown}
                        title="Company"
                        id="basic-nav-dropdown1">
                        <Nav.Link href="/about" role="button" style={dropdownStyles}>Company</Nav.Link>

                        <Dropdown.Menu as="ul" className="dropdown-menu" style={dropdownStyles}>
                          <Dropdown.Item href="about" className="dropdown center-item hidden-xs" style={dropdownStyles}>
                            <li>
                              <img className="img-responsive sub-item-img" style={{ float: "left", paddingRight: 5 + 'px' }} src="https://res.cloudinary.com/dbt0ljnls/image/upload/c_scale,q_100,w_25/v1486028447/DT-LOGO-VECTOR-_w7ik4x.png" />
                              <p className="sub-item">About Direktek</p>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item href="/brands/">
                            <li>
                              <FontAwesomeIcon
                                className="fa fa-cube fa-lg sub-item-icon"
                                icon={faCube}
                                size="lg"
                                fixedWidth
                                style={{
                                  float: "left",
                                  paddingRight: 5 + 'px',
                                  color: "#3e4143 !important",
                                  fontSize: "1.3333333em"
                                }} />
                              <p>Brand Partners</p>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item href="/platform-partners">
                            <li>
                              <FontAwesomeIcon
                                className="fa fa-cube fa-lg sub-item-icon"
                                icon={faCode}
                                size="lg"
                                fixedWidth
                                style={{
                                  float: "left",
                                  paddingRight: 5 + 'px',
                                  fontSize: "1.33em"
                                }} />
                              <p>Platform Partners</p>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item href="/jobs">
                            <li>
                              <FontAwesomeIcon
                                className="fa fa-cube fa-lg sub-item-icon"
                                icon={faBriefcase}
                                size="lg"
                                fixedWidth
                                style={{
                                  float: "left",
                                  paddingRight: 5 + 'px',
                                  fontSize: "1.33em"
                                }} />
                              <p>Jobs</p>
                            </li>
                          </Dropdown.Item>
                          <Dropdown.Item href="/contact">
                            <li>
                              <FontAwesomeIcon
                                className="fa fa-cube fa-lg sub-item-icon"
                                icon={faEnvelope}
                                size="lg"
                                fixedWidth
                                style={{
                                  float: "left",
                                  paddingRight: 5 + 'px',
                                  fontSize: "1.33em"
                                }} />
                              <p>Contact</p>
                            </li>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                    <li className="right-item hidden-xs">
                      <a href="/brands" className="btn-item">
                        <FontAwesomeIcon
                          className="fa fa-search fa-lg sub-item-icon"
                          icon={faSearch}
                          size="lg"
                          fixedWidth
                          style={{
                            float: "right",
                            paddingRight: 5 + 'px',
                            fontSize: "1.33em"
                          }} />
                      </a>
                    </li>
                    <li className="right-item hidden-xs">
                      <a href="https://help.direktek.co.uk">
                          Help
                        <span className="caret-right" />
                      </a>
                    </li>
                  </ul>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </nav>
      </header >
    );
  }

  private toggleCompany() {
    this.setState(prevState => ({
      companyDropdown: !prevState.companyDropdown
    }));
  }

  private toggleProduct() {
    this.setState(prevState => ({
      productsDropdown: !prevState.productsDropdown
    }));
  }
  private toggleServices() {
    this.setState(prevState => ({
      servicesDropdown: !prevState.servicesDropdown
    }));
  }

}

interface HeaderState {
  companyDropdown: boolean;
  productsDropdown: boolean;
  servicesDropdown: boolean;
}

interface DefaultLayoutProps extends React.HTMLProps<HTMLDivElement> {
  location?: {
    pathname: string;
  };
}

class DefaultLayout extends React.PureComponent<DefaultLayoutProps, {}> {
  public render() {
    return (
      <div>
        <Helmet
          title="DirekTek | Channel platform for imaging, optical, home &amp; office products"
          meta={[{ name: 'description', content: 'UK & European trade-only distributor of imaging, optical, home & office products. Brands incl. Canon, PRAKTICA, Samsung, Sony, Kodak, Proper & Olympus' }, { name: 'keywords', content: '' }]}
          base={{ href: "/", target: "_self" }}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
          ]}
        />
        <Header />
      </div>
    );
  }
}

export default DefaultLayout;
