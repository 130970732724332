import * as React from 'react';
import {withCookies, Cookies} from 'react-cookie'
// import { instanceOf } from 'prop-types';

interface CookiePopupState {
    cookieBar: boolean,
    accepted: boolean,
    showDecline: boolean,
    declined: boolean
}

interface CookiePopupProps { 
    handleClose: Function,
    cookies: Cookies
}
class CookiePopup extends React.Component<CookiePopupProps, CookiePopupState> {
    
    constructor(props: CookiePopupProps) {
        super(props)
        const { cookies } = props
        console.log(cookies)
        let dtcookie = cookies.get('DTCookie')
        // const dtcookie = cookies.getAll(); 
        let cookieValue: CookiePopupState
        if(dtcookie != null && dtcookie.value === false )
            cookieValue = JSON.parse(this.fromBase64(dtcookie.value))
        else
            cookieValue = {
                cookieBar: true,
                accepted: false,
                showDecline: false,
                declined: false
            }
        if(cookieValue.accepted || cookieValue.declined) {
                cookieValue.cookieBar = false
        }
        
        this.state = cookieValue
    }
    public render() {
        const visible = this.state.cookieBar ? 'visible' : 'hidden'; 
        return (
            <div style={{ visibility: visible, position: 'sticky' }}>
            <p className='cookieok_intro' onClick={this.dismissCookiePopup}> "We use cookies to give you the best possible experience on our site. By continuing to use the site you agree to our use of cookies." <a href='#' className='cookieok_dismiss' onClick={this.dismissCookiePopup}>Dismiss</a> | <a href='/PrivacyPolicy' target='_blank' className='moreinfo'>More Information</a> <a href='#' className='cookieok_dismiss cookieok_x' onClick={() => this.setState({ cookieBar: false })}>X</a></p>
        </div>
        )
    }

    private setCookie = () => {
        
        const options = {
            path: "/",
            domain: "direktek.co.u", 
            sameSite: "",
            expires: "",
        }
        const date = new Date();
        date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
        options.expires = date.toUTCString(); 
    }

    private showCookieRequest = () => {
            // let barHeight: number = Number(window.getComputedStyle(this.refs.cookieok_bar, null).getPropertyValue('height'))
            // var newMargin = bodyMargin + barHeight;
            // $("body").css("margin-top", newMargin);

        // $('<link />', { href: '/globalincludes/cookiecheck/cookieok.css', media: 'screen', rel: 'stylesheet', type: 'text/css' }).appendTo('head');
        // $(document).ready(() => {
        //     if (_cookieOK.accepted || _cookieOK.declined) {
        //         return;
        //     }
        // this.setState({cookieBar:})


        // this.refs.dismissBar = function () {
           

        // };

        // window.setTimeout(adjustBodyMargin, 100);
        // $(window).resize(function () {
        //     window.setTimeout(adjustBodyMargin, 100);
        // });
        // });
    }
    private toBase64 = (s: string) => window.btoa(s)
    private fromBase64 = (s: string) => window.atob(s)

    private extendObject(...objects: any[]) {
        for (var i = 1; i < objects.length; i++)
            for (var key in objects[i])
                if (objects[i].hasOwnProperty(key))
                    objects[0][key] = objects[i][key];
        return objects[0];
    }
}
export default withCookies(CookiePopup)